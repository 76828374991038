.r1NtyNg8 {
  max-width: 200px;
}

._1shPL0Am > div {
  margin: 0;
}

._3sZFV80u > svg {
  width: 20px;
  height: 20px;
}

._1yhLB2-y > svg {
  width: 30px;
  height: 30px;
}

._3yR6Rkz_ > svg {
  width: 40px;
  height: 40px;
}

._3r_Anprc > svg {
  width: 50px;
  width: 50px;
}

._3ntz1Qi8 > svg {
  fill: white;
  width: 35px;
}

._3ntz1Qi8:hover {
  cursor: pointer;
}

._3dMfwL50:hover {
  cursor: pointer;
}

._3OzsH1ww {
  color: white;
  padding-left: 10px;
}

._2SeCVSdw a {
  color: white;
}

._2SeCVSdw span {
  color: white;
}

._2SeCVSdw span:last-child a {
  color: white;
}

li.XBYYe_gT {
  padding: 0 !important;
  margin: 0px !important;
  height: 64px !important;
}

li.XBYYe_gT:after {
  border-right: 3px solid #f5a623 !important;
}

._3e9q6hSG {
  bottom: 20px;
}

aside._6NsepYVP {
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  z-index: 10;
  height: 100vh;
  background: #fff;
  @media only screen and (max-width: 950px) {
    display: none;
  }
}

._2kq6_9JC > svg {
  height: 100%;
  fill: #000000;
}

.kn2DYAYZ > svg {
  fill: #f5a623;
}

._3FQ59GF9 {
  padding: 20px;
}

._1Y7yz9wz {
  transform: rotate(45deg);
}

.buK7eEd0 {
  width: 80%;
  margin: auto;
}

._36G2fCvZ > div > div:nth-child(2) {
  background: #f5a623 !important;
}

._2-BXSjez {
  border: 1px solid #d9d9d9;
}

._2-BXSjez:hover {
  border-color: #ffc14d;
}

div.FSfcVVuT thead[class*='ant-table-thead'] > tr th {
  background-color: #d8dde6;
}

div.J-2LG9-R ul[class*='ant-table-pagination'] {
  margin-right: 16px;
}

div._2ErsNFt- thead[class*='ant-table-thead'] th {
  background-color: #2f2075 !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  color: white;
}

div._6_WJatxA tr[class*='ant-table-expanded-row'] > td {
  background: #2f2075;
  color: white;
}

._1kPAN8vX > svg {
  width: 20px;
  fill: #2f2075;
}

._3mO3_lsJ > svg {
  transform: rotate(45deg);
  transition-duration: 0.3s;
}
